import "beercss";
import "material-dynamic-colors";

import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import * as banner from "./assets/banner.jpg?width=1400";
import * as logo from "./assets/logo.jpg?width=96";
import { Elm } from "./frontend/Main.elm";

const firebaseConfig = {
  production: {
    apiKey: "AIzaSyBT-xedKsjTGneNullkoAAxZOEmI7vN150",
    authDomain: "shop4mum.com",
    projectId: "shop4mum",
    storageBucket: "shop4mum.appspot.com",
    messagingSenderId: "71195468539",
    appId: "1:71195468539:web:85ff559b8670a42156465e",
  },
  default: {
    apiKey: "AIzaSyDrGzLJGgttidXTDNAgXMPK8pzfXvJ1Tew",
    authDomain: "shop4mum-qa.firebaseapp.com",
    projectId: "shop4mum-qa",
    storageBucket: "shop4mum-qa.appspot.com",
    messagingSenderId: "526461291954",
    appId: "1:526461291954:web:3ba683ea7ae0c114d328ab",
  },
};

initializeApp(firebaseConfig[process.env.ELM_ENV] || firebaseConfig.default);

const appPrefix = `shop4mum-${process.env.ELM_ENV}`;
const cookieKey = `${appPrefix}:cookieNoticeAccepted`;
const langKey = `${appPrefix}:lang`;

const providerGoogle = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
const auth = getAuth();

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {
    env: process.env.ELM_ENV,
    lang:
      localStorage.getItem(langKey) || navigator.language.slice(0, 2) || "en",
    domainURL: process.env.ELM_DOMAIN_URL,
    logoURL: logo,
    bannerURL: banner,
  },
});

async function getMe(token) {
  const response = await fetch(process.env.ELM_DOMAIN_URL + "/api/me", {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
}

app.ports.signInWithGoogle.subscribe(() => {
  signInWithPopup(auth, providerGoogle)
    .then((result) => {
      result.user.getIdToken().then((idToken) => {
        getMe(idToken)
          .then((signedInUser) => {
            app.ports.signedIn.send(signedInUser);
          })
          .catch((error) => {
            app.ports.signedIn.send({
              code: error.code,
              message: error.message,
            });
          });
      });
    })
    .catch((error) => {
      app.ports.signedIn.send({
        code: error.code,
        message: error.message,
      });
    });
});

app.ports.signInWithFacebook.subscribe(() => {
  signInWithPopup(auth, providerFacebook)
    .then((result) => {
      result.user.getIdToken().then((idToken) => {
        getMe(idToken)
          .then((signedInUser) => {
            app.ports.signedIn.send(signedInUser);
          })
          .catch((error) => {
            app.ports.signedIn.send({
              code: error.code,
              message: error.message,
            });
          });
      });
    })
    .catch((error) => {
      app.ports.signedIn.send({
        code: error.code,
        message: error.message,
      });
    });
});

app.ports.signOut.subscribe(() => {
  signOut(auth);
});

app.ports.setLang.subscribe((lang) => {
  localStorage.setItem(langKey, lang);
});

//  Observer on user info
onAuthStateChanged(auth, (user) => {
  if (user) {
    user.getIdToken().then((idToken) => {
      getMe(idToken)
        .then((signedInUser) => {
          app.ports.signedIn.send(signedInUser);
        })
        .catch((error) => {
          app.ports.signedIn.send({
            code: error.code,
            message: error.message,
          });
        });
    });
  } else {
    app.ports.signedIn.send(null);
  }
});

// Function to create and append the cookie notice
function createCookieNotice() {
  const noticeContainer = document.createElement("div");
  noticeContainer.style.position = "fixed";
  noticeContainer.style.bottom = "0";
  noticeContainer.style.left = "0";
  noticeContainer.style.right = "0";
  noticeContainer.style.backgroundColor = "#333";
  noticeContainer.style.color = "#fff";
  noticeContainer.style.padding = "10px";
  noticeContainer.style.textAlign = "center";
  noticeContainer.style.zIndex = "9999";

  const noticeText = document.createElement("p");
  noticeText.textContent =
    "We only use essential cookies and do not track your activity.";

  const closeButton = document.createElement("button");
  closeButton.textContent = "Close";
  closeButton.addEventListener("click", () => {
    noticeContainer.style.display = "none";
    localStorage.setItem(cookieKey, true);
  });

  noticeContainer.appendChild(noticeText);
  noticeContainer.appendChild(closeButton);
  document.body.appendChild(noticeContainer);
}

// Check if the user has accepted the notice before
const hasAcceptedNotice = localStorage.getItem(cookieKey);
if (!hasAcceptedNotice) {
  createCookieNotice();
}

if (navigator.userAgent.includes("Android")) {
  if (navigator.userAgent.includes("wv") || navigator.userAgent.includes("WebView")) {
    const noticeContainer = document.createElement("div");
    noticeContainer.style.position = "fixed";
    noticeContainer.style.fontSize = "18px";
    noticeContainer.style.width = "100%";
    noticeContainer.style.height = "100%";
    noticeContainer.style.top = "0";
    noticeContainer.style.left = "0";
    noticeContainer.style.right = "0";
    noticeContainer.style.backgroundColor = "#333";
    noticeContainer.style.color = "#fff";
    noticeContainer.style.padding = "10px";
    noticeContainer.style.textAlign = "center";
    noticeContainer.style.overflowX = "hidden";
    noticeContainer.style.zIndex = "9999";

    const noticeText = document.createElement("p");
    noticeText.textContent =
      "You are currently using an in-app browser, which is considered insecure. To safeguard your security, we recommend opening this link in a standard browser. You can do this by selecting the WebView menu option to open in a regular browser or by copying and pasting the link into your preferred browser manually. Please note that this is not an issue with Shop4Mum. Leading industry experts widely recognise in-app browsers (WebView) as less secure, and our priority is to protect your information.";

    noticeContainer.appendChild(noticeText);
    document.body.appendChild(noticeContainer);
  }
}
