port module Main exposing (..)

import Api
import Api.Data exposing (..)
import Api.Request.Carts exposing (..)
import Api.Request.Products exposing (..)
import Api.Time exposing (Posix, dateTimeToString)
import AppUrl
import Browser
import Browser.Navigation
import File
import Html as H
import Html.Attributes as A
import Html.Events as E
import Http
import Json.Decode
import Markdown.Option exposing (..)
import Markdown.Render exposing (MarkdownMsg(..), MarkdownOutput(..))
import Page exposing (Page(..))
import PrivacyPolicy exposing (viewPrivacyPolicy)
import RemoteData exposing (WebData)
import TermsAndConditions exposing (viewTermsAndConditions)
import Time exposing (millisToPosix)
import Translation exposing (Lang(..), i18n, i18nFrom, stringToLang)
import Url exposing (Url)


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }



-- CONSTANTS


logoURL : String
logoURL =
    "/assets/logox512.jpeg"


bannerURL : String
bannerURL =
    "/assets/bannerx1400.jpeg"


largeWrapper : String
largeWrapper =
    "fill middle-align center-align"


mediumWrapper : String
mediumWrapper =
    "fill middle-align center-align"



-- MODEL


type alias Flags =
    Json.Decode.Value


type Field
    = ProductTitle
    | ProductSummary
    | ProductCategories Category
    | ProductCurrency
    | ProductPrice
    | ProductDiscountPrice
    | ProductStock
    | ProductImages
    | ProductPublished


type alias Sorting =
    ( SortBy, Direction )


type SortBy
    = Latest
    | Pricing


type Direction
    = Asc
    | Desc


type alias Model =
    { key : Browser.Navigation.Key
    , page : Maybe Page.Page
    , lang : Lang
    , editing : Bool
    , appReady : Bool
    , user : Maybe User
    , cart : Maybe Cart
    , products : WebData (List Product)
    , product : WebData Product
    , orders : WebData (List Cart)
    , order : WebData Cart
    , config : Config
    , search : String
    , sorting : Sorting
    , error : String
    }


type alias Config =
    { env : String
    , lang : String
    , domainURL : String
    , logoURL : String
    , bannerURL : String
    }


configDecoder : Json.Decode.Decoder Config
configDecoder =
    Json.Decode.map5 Config
        (Json.Decode.field "env" Json.Decode.string)
        (Json.Decode.field "lang" Json.Decode.string)
        (Json.Decode.field "domainURL" Json.Decode.string)
        (Json.Decode.field "logoURL" Json.Decode.string)
        (Json.Decode.field "bannerURL" Json.Decode.string)


init :
    Flags
    -> Url
    -> Browser.Navigation.Key
    -> ( Model, Cmd Msg )
init flags url key =
    let
        decoded =
            Json.Decode.decodeValue configDecoder <|
                flags

        parsed =
            case decoded of
                Ok config ->
                    config

                Err _ ->
                    { env = "dev"
                    , lang = "en"
                    , domainURL = "http://localhost:8080"
                    , logoURL = logoURL
                    , bannerURL = bannerURL
                    }

        inited =
            { key = key
            , page = Page.parse (AppUrl.fromUrl url)
            , lang =
                if parsed.lang == "zh" then
                    Zh

                else
                    En
            , editing = False
            , appReady = False
            , user = Nothing
            , cart = Nothing
            , products = RemoteData.NotAsked
            , product = RemoteData.NotAsked
            , orders = RemoteData.NotAsked
            , order = RemoteData.NotAsked
            , config = parsed
            , search = ""
            , sorting = ( Latest, Desc )
            , error = ""
            }

        ( model, cmdMsg ) =
            update (UrlChanged url) inited
    in
    ( model
    , cmdMsg
    )



-- MSG


type Msg
    = NoOp
    | LinkClicked Browser.UrlRequest
    | UrlChanged Url
    | SetLang Lang
    | PushPage Page
    | SignInWithGoogle
    | SignInWithFacebook
    | SignedIn Json.Decode.Value
    | SignOut
    | SetSearch String
    | SetSorting Sorting
    | ToggleEdit
    | EditField Field String
    | MarkdownMsg Markdown.Render.MarkdownMsg
    | ApiUploadProductImage (List File.File)
    | ApiPutProduct
    | ApiGetProducts
    | ApiGotProducts (WebData (List Product))
    | ApiGetProduct String
    | ApiGotProduct (WebData Product)
    | ApiGetCart
    | ApiGotCart (WebData (List Cart))
    | ApiGetOrders
    | ApiGotOrders (WebData (List Cart))
    | ApiGetOrder String
    | ApiGotOrder (WebData Cart)
    | ApiAddToCart String Int
    | ApiCheckoutCart Cart
    | ApiGotUpdatedCart (WebData Cart)
    | ApiRevertOrder String
    | ApiShipOrder String
    | ApiDeliverOrder String
    | ApiCancelOrder String
    | ApiRefundOrder String


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        token =
            model.user
                |> Maybe.andThen .token
                |> Maybe.withDefault ""

        apiSendWith cmd req =
            req
                |> Api.withBasePath model.config.domainURL
                |> Api.send (RemoteData.fromResult >> cmd)
    in
    case msg of
        NoOp ->
            ( { model | error = "" }, Cmd.none )

        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Browser.Navigation.pushUrl
                        model.key
                      <|
                        Url.toString url
                    )

                Browser.External url ->
                    ( model
                    , Browser.Navigation.load url
                    )

        UrlChanged url ->
            let
                page =
                    Page.parse (AppUrl.fromUrl url)

                isAdmin =
                    model.user
                        |> Maybe.map .isAdmin
                        |> Maybe.withDefault False

                ( model_, cmdMsg ) =
                    if model.appReady then
                        case page of
                            Just PageProducts ->
                                update ApiGetProducts model

                            Just (PageProduct id) ->
                                case id of
                                    "new" ->
                                        let
                                            body =
                                                { id = "new"
                                                , title = ""
                                                , summary = ""
                                                , categories = []
                                                , images = []
                                                , price = Money CurrencyGBP 0 0 False
                                                , stock = -1
                                                , published = False
                                                , metadata =
                                                    { created = millisToPosix 0
                                                    , updated = millisToPosix 0
                                                    , liveness = True
                                                    }
                                                }
                                        in
                                        if not isAdmin then
                                            update (PushPage PageAuth) model

                                        else
                                            ( { model
                                                | editing = True
                                                , product = RemoteData.Success body
                                              }
                                            , Cmd.none
                                            )

                                    _ ->
                                        update (ApiGetProduct id) model

                            Just PageOrders ->
                                if not isAdmin then
                                    update (PushPage PageAuth) model

                                else
                                    update ApiGetOrders model

                            Just PageAuth ->
                                update ApiGetOrders model

                            Just (PageOrder id) ->
                                if not isAdmin then
                                    update (PushPage PageAuth) model

                                else
                                    update (ApiGetOrder id) model

                            Just (PageOrderSuccess id) ->
                                update (ApiGetOrder id) model

                            Just PageCart ->
                                update ApiGetCart model

                            _ ->
                                ( model, Cmd.none )

                    else
                        ( model, Cmd.none )
            in
            ( { model_
                | page = page
              }
            , cmdMsg
            )

        SetLang lang ->
            ( { model | lang = lang }, setLang <| stringToLang lang )

        PushPage page ->
            ( model
            , Page.toString page
                |> Browser.Navigation.pushUrl model.key
            )

        SignInWithGoogle ->
            ( model, signInWithGoogle () )

        SignInWithFacebook ->
            ( model, signInWithFacebook () )

        SignedIn v ->
            case Json.Decode.decodeValue userDecoder v of
                Ok user ->
                    ( { model
                        | user = Just user
                        , appReady = True
                      }
                    , if model.user == Nothing then
                        Cmd.batch
                            [ user.token
                                |> Maybe.withDefault ""
                                |> getLatestCart
                                |> apiSendWith ApiGotCart
                            , Page.toString (Maybe.withDefault PageAuth model.page)
                                |> Browser.Navigation.pushUrl model.key
                            ]

                      else
                        Cmd.none
                    )

                Err _ ->
                    ( { model
                        | user = Nothing
                        , appReady = True
                      }
                    , Page.toString (Maybe.withDefault PageAuth model.page)
                        |> Browser.Navigation.pushUrl model.key
                    )

        SignOut ->
            ( model, signOut () )

        SetSearch search ->
            ( { model | search = search }, Cmd.none )

        SetSorting sorting ->
            ( { model | sorting = sorting }, Cmd.none )

        ToggleEdit ->
            let
                isAdmin =
                    model.user
                        |> Maybe.map .isAdmin
                        |> Maybe.withDefault False
            in
            if isAdmin then
                ( { model | editing = not model.editing }, Cmd.none )

            else
                ( model, Cmd.none )

        EditField field txt ->
            case field of
                ProductTitle ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                p =
                                    { product | title = txt }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductSummary ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                p =
                                    { product | summary = txt }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductCategories cat ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                cats =
                                    if List.member cat product.categories then
                                        List.filter (\c -> c /= cat) product.categories

                                    else
                                        List.sortBy stringFromCategory <| cat :: product.categories

                                p =
                                    { product | categories = cats }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductCurrency ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                currency =
                                    currencyVariants
                                        |> List.filter
                                            (\c -> stringFromCurrency c == txt)
                                        |> List.head
                                        |> Maybe.withDefault CurrencyGBP

                                price =
                                    product.price

                                p =
                                    { product
                                        | price = { price | currency = currency }
                                    }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductPrice ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                trimmed =
                                    String.toInt txt
                                        |> Maybe.withDefault 0

                                amount =
                                    Json.Decode.decodeString Json.Decode.int txt
                                        |> Result.withDefault trimmed

                                price =
                                    product.price

                                p =
                                    { product | price = { price | amount = amount } }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductDiscountPrice ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                trimmed =
                                    String.toInt txt
                                        |> Maybe.withDefault 0

                                amount =
                                    Json.Decode.decodeString Json.Decode.int txt
                                        |> Result.withDefault trimmed

                                price =
                                    product.price

                                p =
                                    { product | price = { price | discount = amount } }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductStock ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                trimmed =
                                    String.toInt txt
                                        |> Maybe.withDefault -1

                                p =
                                    { product | stock = trimmed }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductPublished ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                p =
                                    { product | published = not product.published }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                ProductImages ->
                    case model.product of
                        RemoteData.Success product ->
                            let
                                ims =
                                    List.filter (\i -> i /= txt) product.images

                                p =
                                    { product | images = ims }
                            in
                            ( { model | product = RemoteData.Success p }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

        MarkdownMsg _ ->
            ( model, Cmd.none )

        ApiUploadProductImage files ->
            case model.product of
                RemoteData.Success product ->
                    ( { model | product = RemoteData.Loading }
                    , uploadProductImages product.id files token
                        |> apiSendWith ApiGotProduct
                    )

                _ ->
                    ( { model | product = RemoteData.Loading }, Cmd.none )

        ApiPutProduct ->
            case model.product of
                RemoteData.Success product ->
                    let
                        body =
                            { title = product.title
                            , summary = product.summary
                            , categories = product.categories
                            , images = product.images
                            , price = product.price
                            , stock = product.stock
                            , published = product.published
                            }

                        action =
                            case product.id of
                                "new" ->
                                    postProduct body token

                                _ ->
                                    updateProduct product.id body token
                    in
                    ( { model | product = RemoteData.Loading }
                    , action
                        |> apiSendWith ApiGotProduct
                    )

                _ ->
                    ( { model | product = RemoteData.Loading }, Cmd.none )

        ApiGetCart ->
            ( { model
                | cart = Nothing
                , error = ""
              }
            , getLatestCart token
                |> apiSendWith ApiGotCart
            )

        ApiGotCart response ->
            case response of
                RemoteData.Success latests ->
                    ( { model
                        | cart = List.head latests
                      }
                    , Cmd.none
                    )

                _ ->
                    ( { model
                        | cart = Nothing
                      }
                    , Cmd.none
                    )

        ApiGetProducts ->
            ( { model
                | products = RemoteData.Loading
                , error = ""
              }
            , getProducts Nothing token
                |> apiSendWith ApiGotProducts
            )

        ApiGotProducts response ->
            case response of
                RemoteData.Success products ->
                    ( { model
                        | products = RemoteData.Success products
                      }
                    , Cmd.none
                    )

                _ ->
                    ( { model | products = response }, Cmd.none )

        ApiGetProduct id ->
            ( { model
                | product = RemoteData.Loading
                , error = ""
              }
            , getProduct id token
                |> apiSendWith ApiGotProduct
            )

        ApiGotProduct response ->
            case ( model.page, response ) of
                ( Just (PageProduct "new"), RemoteData.Success product ) ->
                    update (PushPage <| PageProduct product.id) { model | product = response }

                ( _, _ ) ->
                    ( { model | product = response }, Cmd.none )

        ApiGetOrders ->
            ( { model
                | orders = RemoteData.Loading
                , error = ""
              }
            , getCarts (Just "state:ordered|shipped|delivered|cancelled|refunded") token
                |> apiSendWith ApiGotOrders
            )

        ApiGotOrders response ->
            case response of
                RemoteData.Success orders ->
                    ( { model
                        | orders = RemoteData.Success orders
                      }
                    , Cmd.none
                    )

                _ ->
                    ( { model | orders = response }, Cmd.none )

        ApiGetOrder id ->
            ( { model
                | order = RemoteData.Loading
                , error = ""
              }
            , getCart id token
                |> apiSendWith ApiGotOrder
            )

        ApiGotOrder response ->
            case model.page of
                Just PageOrders ->
                    update ApiGetOrders { model | order = response }

                Just (PageOrderSuccess _) ->
                    update ApiGetCart { model | order = response }

                _ ->
                    ( { model | order = response }, Cmd.none )

        ApiAddToCart productId qty ->
            let
                hasUser =
                    model.user /= Nothing

                hasCart =
                    model.cart /= Nothing

                cartId =
                    model.cart
                        |> Maybe.map .id
                        |> Maybe.withDefault "latest"

                item =
                    LineItem productId Nothing qty

                cmdMsg =
                    case ( hasUser, hasCart ) of
                        ( False, _ ) ->
                            Page.toString PageAuth
                                |> Browser.Navigation.pushUrl model.key

                        ( _, _ ) ->
                            updateCartItem cartId item token
                                |> apiSendWith ApiGotUpdatedCart
            in
            ( model, cmdMsg )

        ApiCheckoutCart cart ->
            let
                hasUser =
                    model.user /= Nothing

                hasItems =
                    quantityItems cart.items > 0

                cmdMsg =
                    case ( hasUser, hasItems ) of
                        ( False, _ ) ->
                            Page.toString PageAuth
                                |> Browser.Navigation.pushUrl model.key

                        ( _, True ) ->
                            checkoutCart cart.id token
                                |> apiSendWith ApiGotUpdatedCart

                        ( _, _ ) ->
                            Cmd.none
            in
            ( model, cmdMsg )

        ApiGotUpdatedCart response ->
            case response of
                RemoteData.Success cart ->
                    ( { model
                        | cart = Just cart
                        , error = ""
                      }
                    , Cmd.none
                    )

                RemoteData.Failure err ->
                    ( { model
                        | error = httpError model.lang err
                      }
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        ApiRevertOrder id ->
            ( { model | order = RemoteData.Loading }
            , updateCartState id StateOrdered token
                |> apiSendWith ApiGotOrder
            )

        ApiShipOrder id ->
            ( { model | order = RemoteData.Loading }
            , updateCartState id StateShipped token
                |> apiSendWith ApiGotOrder
            )

        ApiDeliverOrder id ->
            ( { model | order = RemoteData.Loading }
            , updateCartState id StateDelivered token
                |> apiSendWith ApiGotOrder
            )

        ApiCancelOrder id ->
            ( { model | order = RemoteData.Loading }
            , updateCartState id StateCancelled token
                |> apiSendWith ApiGotOrder
            )

        ApiRefundOrder id ->
            ( { model | order = RemoteData.Loading }
            , updateCartState id StateRefunded token
                |> apiSendWith ApiGotOrder
            )



-- CMD


port signInWithGoogle : () -> Cmd msg


port signInWithFacebook : () -> Cmd msg


port signedIn : (Json.Decode.Value -> msg) -> Sub msg


port signOut : () -> Cmd msg


port setLang : String -> Cmd msg



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch [ signedIn SignedIn ]



-- HELPERS


productCardImage : Product -> String
productCardImage product =
    List.head product.images
        |> Maybe.map uploadURL
        |> Maybe.withDefault logoURL


uploadURL : String -> String
uploadURL im =
    "/uploads/" ++ im


thumbnailURL : String -> String
thumbnailURL im =
    "/uploads/thumb-" ++ im


httpError : Lang -> Http.Error -> String
httpError lang error =
    let
        err =
            case error of
                Http.BadUrl url ->
                    "The URL " ++ url ++ " was invalid"

                Http.Timeout ->
                    "Unable to reach the server, try again"

                Http.NetworkError ->
                    "Network error, check your internet connection"

                Http.BadStatus 500 ->
                    "The server had a problem, try again later"

                Http.BadStatus 409 ->
                    "There are conflicts, adding product with mixed currency is not allowed"

                Http.BadStatus 422 ->
                    "Verify the information you provided and try again"

                Http.BadStatus 403 ->
                    "Your access or permission is denied"

                Http.BadStatus 401 ->
                    "Please sign in first and try again"

                Http.BadStatus 400 ->
                    "Verify your information and try again"

                Http.BadStatus _ ->
                    "Unknown error"

                Http.BadBody errorMessage ->
                    errorMessage
    in
    i18nFrom lang err


formatPrice : Money -> String
formatPrice money =
    stringFromCurrency money.currency
        ++ " "
        ++ String.fromFloat (toFloat (money.amount - money.discount) / 100)
        ++ (if money.tax then
                " (tax inclusive"

            else
                ""
           )


viewPrice : Lang -> Money -> H.Html Msg
viewPrice lang money =
    let
        txtCurrency =
            stringFromCurrency money.currency

        txtTax =
            if money.tax then
                " (tax inclusive)"

            else
                ""

        amount =
            toFloat money.amount / 100

        discounted =
            toFloat (money.amount - money.discount) / 100

        amountLabel =
            if money.discount > 0 then
                H.span [ A.class "padding small" ]
                    [ H.span [ A.class "overline red-text" ] [ H.text <| txtCurrency ++ " " ++ String.fromFloat amount ]
                    , H.span [ A.class "space small" ] [ i18n lang " " ]
                    , H.b [] [ H.text <| txtCurrency ++ " " ++ String.fromFloat discounted ]
                    , H.text txtTax
                    ]

            else
                H.span []
                    [ H.b []
                        [ H.text <| txtCurrency ++ " " ++ String.fromFloat amount ]
                    , H.text txtTax
                    ]
    in
    H.label []
        [ amountLabel
        ]


formatDateTime : Posix -> String
formatDateTime posix =
    dateTimeToString posix
        |> String.split "."
        |> List.take 1
        |> String.join ""
        |> String.split "T"
        |> String.join " "


cartStateTitle : Cart -> String
cartStateTitle cart =
    case cart.state of
        StateOngoing ->
            "Cart Summary"

        StateOrdered ->
            "Thank you! Your Order Summary"

        StateShipped ->
            "Your Shipped Order Summary"

        other ->
            let
                m =
                    stringFromState other
            in
            "Your " ++ m ++ " Order Summary"


quantityItems : List LineItem -> Int
quantityItems items =
    List.foldl (\item acc -> acc + item.quantity) 0 items



-- VIEWS


viewPage : Model -> H.Html Msg
viewPage model =
    if not model.appReady then
        viewLoading

    else
        case model.page of
            Just PageAuth ->
                viewAuth model

            Just PageProducts ->
                viewProducts model

            Just (PageProduct _) ->
                if model.editing then
                    viewAdminProduct model.lang model.product

                else
                    viewProduct model.lang model.product

            Just PageCart ->
                viewLatestCart model.lang model.cart

            Just (PageOrderSuccess _) ->
                case model.user of
                    Nothing ->
                        viewAuth model

                    Just _ ->
                        viewOrderSummary model.lang model.order

            Just PageOrders ->
                viewAdminOrders model

            Just PagePrivacyPolicy ->
                viewPrivacyPolicy

            Just PageTermsAndConditions ->
                viewTermsAndConditions

            _ ->
                viewHome model


viewSearch : Model -> H.Html Msg
viewSearch model =
    H.div [ A.class "field label prefix border s12 l6 max" ]
        [ viewIcon "search"
        , H.input
            [ A.attribute "type" "text"
            , A.value model.search
            , E.onInput SetSearch
            ]
            []
        , H.label [] [ i18n model.lang "Search" ]
        ]


viewSorting : Model -> H.Html Msg
viewSorting model =
    H.div [ A.class "s12 l6 center-align middle-align" ]
        [ case model.page of
            Just PageOrders ->
                H.div [ A.class "max" ]
                    [ H.button
                        [ A.class "small border"
                        , E.onClick <| SetSearch "state:ordered"
                        ]
                        [ viewIcon "filter_alt", H.span [] [ i18n model.lang "Ordered" ] ]
                    , H.button
                        [ A.class "small border"
                        , E.onClick <| SetSearch "state:shipped"
                        ]
                        [ viewIcon "filter_alt", H.span [] [ i18n model.lang "Shipped" ] ]
                    , H.button
                        [ A.class "small border"
                        , E.onClick <| SetSearch "state:delivered"
                        ]
                        [ viewIcon "filter_alt", H.span [] [ i18n model.lang "Delivered" ] ]
                    , H.button
                        [ A.class "small border"
                        , E.onClick <| SetSearch "state:cancelled"
                        ]
                        [ viewIcon "filter_alt", H.span [] [ i18n model.lang "Cancelled" ] ]
                    , H.button
                        [ A.class "small border"
                        , E.onClick <| SetSearch "state:refunded"
                        ]
                        [ viewIcon "filter_alt", H.span [] [ i18n model.lang "Refunded" ] ]
                    ]

            _ ->
                i18n model.lang ""
        , H.div [ A.class "space" ] []
        , H.div [ A.class "max" ]
            [ H.button
                [ A.class
                    ("small "
                        ++ (if Tuple.first model.sorting == Latest then
                                "tertiary"

                            else
                                "secondary"
                           )
                    )
                , E.onClick <| SetSorting ( Latest, Tuple.second model.sorting )
                ]
                [ viewIcon "sort", H.span [] [ i18n model.lang "Latest" ] ]
            , H.button
                [ A.class
                    ("small "
                        ++ (if Tuple.first model.sorting == Pricing then
                                "tertiary"

                            else
                                "secondary"
                           )
                    )
                , E.onClick <| SetSorting ( Pricing, Tuple.second model.sorting )
                ]
                [ viewIcon "sort", H.span [] [ i18n model.lang "Pricing" ] ]
            , H.button
                [ A.class "small tertiary"
                , E.onClick <|
                    SetSorting
                        ( Tuple.first model.sorting
                        , if Tuple.second model.sorting == Asc then
                            Desc

                          else
                            Asc
                        )
                ]
                [ viewIcon
                    (if Tuple.second model.sorting == Asc then
                        "arrow_upward"

                     else
                        "arrow_downward"
                    )
                , H.span [] [ i18n model.lang "Direction" ]
                ]
            , H.select
                [ E.onInput SetSearch ]
                [ H.option
                    [ A.value ""
                    ]
                    [ H.text "ALL" ]
                , H.option
                    [ A.value <| stringFromCurrency CurrencyHKD
                    , A.selected <| String.contains (stringFromCurrency CurrencyHKD) model.search
                    ]
                    [ H.text <| stringFromCurrency CurrencyHKD ]
                , H.option
                    [ A.value <| stringFromCurrency CurrencyGBP
                    , A.selected <| String.contains (stringFromCurrency CurrencyGBP) model.search
                    ]
                    [ H.text <| stringFromCurrency CurrencyGBP ]
                ]
            ]
        ]


view : Model -> Browser.Document Msg
view model =
    { title =
        Maybe.withDefault PageHome model.page
            |> Page.toTitle
            |> i18nFrom model.lang
            |> (++) "Shop4Mum - "
    , body =
        [ viewNav model
        , H.div
            [ A.class "main primary-container responsive" ]
            [ case model.error of
                "" ->
                    case model.page of
                        Just PageProducts ->
                            H.div [ A.class "padding grid" ]
                                [ viewSearch model
                                , viewSorting model
                                ]

                        Just PageOrders ->
                            H.div [ A.class "padding grid" ]
                                [ viewSearch model
                                , viewSorting model
                                ]

                        _ ->
                            i18n model.lang ""

                _ ->
                    H.div [ A.class "cpmtainer large padding" ]
                        [ H.h5 [ A.class "error center-align middle-align" ]
                            [ i18n model.lang model.error ]
                        ]
            , viewPage model
            , H.div [ A.class "padding" ] []
            , viewFooter model.lang
            ]
        ]
    }


viewIconExtra : String -> H.Html Msg
viewIconExtra ico =
    H.i
        [ A.attribute "translate" "no"
        , A.class "extra"
        ]
        [ H.text ico ]


viewIcon : String -> H.Html Msg
viewIcon ico =
    H.i [ A.attribute "translate" "no" ]
        [ H.text ico ]


viewNavLink : Page.Page -> String -> Lang -> String -> H.Html Msg
viewNavLink p ico lang txt =
    H.a [ A.href <| Page.toString p ]
        [ viewIconExtra ico
        , H.div [] [ i18n lang txt ]
        ]


viewNav : Model -> H.Html Msg
viewNav model =
    let
        isAdmin =
            model.user
                |> Maybe.map .isAdmin
                |> Maybe.withDefault False

        authProtected =
            case model.user of
                Nothing ->
                    []

                Just _ ->
                    let
                        numItems =
                            model.cart
                                |> Maybe.map .items
                                |> Maybe.map List.length
                                |> Maybe.withDefault 0

                        cartText =
                            if numItems > 0 then
                                "Cart [" ++ String.fromInt numItems ++ "]"

                            else
                                "Cart"
                    in
                    [ viewNavLink PageCart "shopping_cart" model.lang cartText
                    , case ( isAdmin, model.editing, model.page ) of
                        ( True, False, Just (PageProduct _) ) ->
                            H.button
                                [ A.class "tertiary"
                                , E.onClick ToggleEdit
                                ]
                                [ viewIcon "edit"
                                , H.div [] [ i18n model.lang "Edit" ]
                                ]

                        ( True, True, Just (PageProduct _) ) ->
                            H.button
                                [ A.class "secondary"
                                , E.onClick ToggleEdit
                                ]
                                [ viewIcon "close"
                                , H.div [] [ i18n model.lang "Close" ]
                                ]

                        ( True, _, Just PageProducts ) ->
                            viewNavLink (PageProduct "new") "add" model.lang "Product"

                        ( _, _, _ ) ->
                            i18n model.lang ""
                    , if isAdmin then
                        viewNavLink PageOrders "order_play" model.lang "Orders"

                      else
                        i18n model.lang ""
                    ]
    in
    H.nav [ A.class "top row scroll" ] <|
        [ H.a
            [ A.href <| Page.toString PageHome ]
            [ H.i
                [ A.class "extra"
                , A.attribute "translate" "no"
                ]
                [ H.img [ A.src model.config.logoURL ] [] ]
            , H.div [] [ i18n model.lang "Home" ]
            ]
        , viewNavLink PageAuth "person" model.lang "User"
        , viewNavLink PageProducts "category" model.lang "Products"
        ]
            ++ authProtected
            ++ [ viewLang model ]


viewLang : Model -> H.Html Msg
viewLang model =
    H.div [ A.class "row" ]
        [ H.div
            (case model.lang of
                En ->
                    [ A.class "blue-text bold" ]

                _ ->
                    [ A.style "cursor" "pointer"
                    , E.onClick <| SetLang En
                    ]
            )
            [ H.text <| "En" ]
        , H.div
            (case model.lang of
                Zh ->
                    [ A.class "blue-text bold" ]

                _ ->
                    [ A.style "cursor" "pointer"
                    , E.onClick <| SetLang Zh
                    ]
            )
            [ H.text <| "中文" ]
        ]


viewFooter : Lang -> H.Html Msg
viewFooter lang =
    H.div [ A.class "secondary fill grid middle-align center-align" ]
        [ H.cite [ A.class "s12 m6" ]
            [ i18n lang "- Shop4Mum. Copyright 2024. All rights reserved." ]
        , H.span [ A.class "s12 m6" ]
            [ H.a [ A.href <| Page.toString PagePrivacyPolicy ] [ i18n lang "Privacy Policy" ]
            , H.span [ A.class "padding" ] []
            , H.a [ A.href <| Page.toString PageTermsAndConditions ] [ i18n lang "Terms & Conditions" ]
            ]
        ]


viewAuth : Model -> H.Html Msg
viewAuth model =
    case model.user of
        Nothing ->
            viewSignIn model

        Just _ ->
            viewProfile model


viewSignIn : Model -> H.Html Msg
viewSignIn model =
    H.div [ A.class mediumWrapper ]
        [ H.div [ A.class "center-align" ]
            [ viewIconExtra "login"
            , H.h5 [ A.class "center-align" ]
                [ i18n model.lang "Sign In" ]
            , H.p []
                [ i18n model.lang "Sign in to manage your account and continue your checkout" ]
            , H.div [ A.class "space" ] []
            , H.div [ A.class "padding" ]
                [ H.button [ E.onClick SignInWithGoogle ]
                    [ i18n model.lang "Sign In with Google" ]
                , H.button [ E.onClick SignInWithFacebook ]
                    [ i18n model.lang "Sign In with Facebook" ]
                ]
            ]
        ]


viewProfile : Model -> H.Html Msg
viewProfile model =
    let
        viewOrderHistory =
            case model.orders of
                RemoteData.NotAsked ->
                    H.div [ A.class "grid center-align" ]
                        [ H.h5 [] [ i18n model.lang "Please refresh the page" ] ]

                RemoteData.Failure err ->
                    H.div [ A.class "grid center-align" ]
                        [ H.h5 [] [ H.text <| httpError model.lang err ] ]

                RemoteData.Loading ->
                    H.div [ A.class "grid center-align" ]
                        [ H.h5 [] [ viewLoading ] ]

                RemoteData.Success orders ->
                    if List.isEmpty orders then
                        viewEmptyCart model.lang

                    else
                        List.map (viewOrderCard model.lang) orders
                            |> H.div [ A.class "row scroll" ]
    in
    H.div [ A.class "primary-container padding center-align" ]
        [ H.nav [ A.class "center-align" ]
            [ H.h5 []
                [ i18n model.lang "Order History"
                , viewIconExtra "paid"
                , H.button
                    [ A.class "right secondary"
                    , E.onClick SignOut
                    ]
                    [ i18n model.lang "Sign Out" ]
                ]
            ]
        , H.div [ A.class "space" ] []
        , viewOrderHistory
        ]


viewOrderCard : Lang -> Cart -> H.Html Msg
viewOrderCard lang order =
    let
        itemText =
            String.join " " <|
                [ "Your"
                , stringFromState order.state
                , String.fromInt (quantityItems order.items)
                , "items"
                ]
    in
    H.article [ A.class "small-width border" ]
        [ H.b [] [ H.text <| formatDateTime order.metadata.updated ]
        , H.hr [] []
        , H.br [] []
        , H.p []
            [ H.text itemText ]
        , H.p [] [ viewPrice lang order.total ]
        , H.nav []
            [ H.a
                [ A.class "button"
                , A.href <| Page.toString (PageOrderSuccess order.id)
                ]
                [ i18n lang "View Order" ]
            ]
        ]


viewHome : Model -> H.Html Msg
viewHome model =
    let
        cta =
            H.a
                [ A.class "button"
                , A.href <| Page.toString PageProducts
                , A.alt <| Page.toTitle PageProducts
                ]
                [ i18n model.lang "Happy Shopping!" ]
    in
    H.div
        [ A.class "middle-align center-align" ]
        [ H.div [ A.class "center-align" ]
            [ H.img
                [ A.class "responsive"
                , A.src model.config.bannerURL
                ]
                []
            , H.p []
                [ H.h5 [ A.class "center-align" ]
                    [ i18n model.lang "Welcome to Shop4Mum UK" ]
                , H.nav [ A.class "center-align" ]
                    [ cta ]
                , H.div [ A.class "padding" ]
                    [ H.p []
                        [ i18n model.lang "Welcome to Shop4Mum, your trusted online emporium for quality products with a personal touch. Our journey began back in 2014 as a modest Facebook page, and today, we're proud to serve customers not only in the UK but also in Hong Kong and around the world. Our secret to growth? A steadfast commitment to sincere and dedicated customer service."
                        ]
                    , H.p []
                        [ i18n model.lang "At Shop4Mum, we go the extra mile to ensure our customers' complete satisfaction. Each item is painstakingly inspected before dispatch, and we take any defects or concerns seriously. It's this dedication to excellence that keeps our customers returning, confident in their purchases and eager to engage in meaningful conversations about life." ]
                    , H.p []
                        [ i18n model.lang "What truly distinguishes us is the genuine connections we build with our customers. Over the years, many have become more than just valued patrons – they've become cherished friends. Join the Shop4Mum family today, and experience the difference of a shopping experience where quality, care, and community seamlessly intertwine." ]
                    ]
                ]
            ]
        ]


viewImage : String -> H.Html Msg
viewImage im =
    let
        dest =
            case ( im == logoURL, im == bannerURL ) of
                ( True, _ ) ->
                    im

                ( _, True ) ->
                    im

                _ ->
                    uploadURL im
    in
    H.img
        [ A.class "responsive medium-width"
        , A.width 640
        , A.src dest
        ]
        []


viewLoading : H.Html Msg
viewLoading =
    H.progress [] []


viewProductCard : Lang -> Product -> H.Html Msg
viewProductCard lang product =
    H.article
        [ A.class "no-padding"
        , A.style "min-height" "100%"
        ]
        [ H.img
            [ A.class "responsive small-height"
            , A.src <| productCardImage product
            , A.style "cursor" "pointer"
            , E.onClick <| PushPage (PageProduct product.id)
            ]
            []
        , H.div [ A.class "padding" ]
            [ H.p []
                [ viewPrice lang product.price ]
            , H.nav []
                [ H.a
                    [ A.class "button border"
                    , PageProduct product.id
                        |> Page.toString
                        |> A.href
                    ]
                    [ viewIcon "image" ]
                , H.div [ A.class "max" ] []
                , case ( product.published, product.stock == 0 ) of
                    ( False, _ ) ->
                        H.text "Not published"

                    ( True, True ) ->
                        i18n lang "Out of stock"

                    ( _, _ ) ->
                        H.button
                            [ A.class "button"
                            , E.onClick <| ApiAddToCart product.id 1
                            ]
                            [ viewIcon "add_shopping_cart"
                            , H.span [] [ i18n lang "Buy" ]
                            ]
                ]
            , H.div [ A.class "small-divider" ] []
            , H.p [] [ H.b [] [ H.text product.title ] ]
            , H.div []
                [ H.small [] [ H.text product.summary ] ]
            ]
        ]


sortProducts : String -> Sorting -> List Product -> List Product
sortProducts search sorting items =
    let
        filtered =
            List.filter
                (\item ->
                    let
                        terms =
                            ([ item.title
                             , formatPrice item.price
                             ]
                                ++ List.map stringFromCategory item.categories
                            )
                                |> String.join ", "
                                |> String.toLower
                    in
                    search
                        |> String.toLower
                        |> String.split " "
                        |> List.filter (String.trim >> String.isEmpty >> not)
                        |> List.all (\s -> String.contains s terms)
                )
                items

        sorted =
            List.sortBy
                (\item ->
                    let
                        m =
                            item.metadata

                        p =
                            item.price
                    in
                    case Tuple.first sorting of
                        Latest ->
                            Time.posixToMillis m.updated

                        Pricing ->
                            p.amount
                )
                filtered
    in
    case Tuple.second sorting of
        Asc ->
            sorted

        Desc ->
            List.reverse sorted


sortOrders : String -> Sorting -> List Cart -> List Cart
sortOrders search sorting items =
    let
        filtered =
            List.filter
                (\item ->
                    let
                        u =
                            case item.user of
                                Nothing ->
                                    ""

                                Just user ->
                                    [ user.name
                                    , user.email
                                    , user.phone
                                    , formatAddress user.address
                                    ]
                                        |> String.join ", "

                        terms =
                            [ u
                            , "state:" ++ stringFromState item.state
                            , item.id
                            , item.owner
                            , String.join ", " <|
                                List.map
                                    (\p ->
                                        p.product
                                            |> Maybe.map .title
                                            |> Maybe.withDefault ""
                                    )
                                    item.items
                            ]
                                |> String.join ", "
                                |> String.toLower
                    in
                    search
                        |> String.toLower
                        |> String.split " "
                        |> List.filter (String.trim >> String.isEmpty >> not)
                        |> List.all (\s -> String.contains s terms)
                )
                items

        sorted =
            List.sortBy
                (\item ->
                    let
                        m =
                            item.metadata

                        p =
                            item.total
                    in
                    case Tuple.first sorting of
                        Latest ->
                            Time.posixToMillis m.updated

                        Pricing ->
                            p.amount
                )
                filtered
    in
    case Tuple.second sorting of
        Asc ->
            sorted

        Desc ->
            List.reverse sorted


viewProducts : Model -> H.Html Msg
viewProducts model =
    case model.products of
        RemoteData.NotAsked ->
            H.div [ A.class "grid center-align" ]
                [ H.h5 [] [ i18n model.lang "Please refresh the page" ] ]

        RemoteData.Failure err ->
            H.div [ A.class "grid center-align" ]
                [ H.h5 [] [ H.text <| httpError model.lang err ] ]

        RemoteData.Loading ->
            H.div [ A.class "grid center-align" ]
                [ H.h5 [] [ viewLoading ] ]

        RemoteData.Success products ->
            if List.isEmpty products then
                viewEmptyProduct model.lang

            else
                H.div [ A.class "secondary-text max center-align" ]
                    [ H.b []
                        [ i18n model.lang <|
                            "Please note: you can only select single-currency products to the cart"
                        ]
                    , List.map
                        (\p ->
                            H.div [ A.class "s12 m6 l3" ] <|
                                [ viewProductCard model.lang p ]
                        )
                        (sortProducts model.search model.sorting products)
                        |> H.div [ A.class "grid center-align padding" ]
                    ]


viewProduct : Lang -> WebData Product -> H.Html Msg
viewProduct lang result =
    let
        viewWrapper viewTitle =
            H.div
                [ A.class largeWrapper ]
                [ H.div [ A.class "center-align" ]
                    [ H.h5 [] [ viewTitle ] ]
                ]
    in
    case result of
        RemoteData.NotAsked ->
            viewWrapper <|
                i18n lang "Please refresh the page"

        RemoteData.Failure err ->
            viewWrapper <|
                H.text <|
                    httpError lang err

        RemoteData.Loading ->
            viewWrapper viewLoading

        RemoteData.Success product ->
            let
                viewLabel s =
                    H.span [ A.class "chip small" ] [ H.text s ]
            in
            H.div
                [ A.class "fill center-align padding" ]
                [ H.div
                    [ A.class "center-align" ]
                    [ H.h5
                        [ A.class "center-align" ]
                        [ H.text product.title ]
                    , product.categories
                        |> List.map stringFromCategory
                        |> List.map viewLabel
                        |> H.p []
                    , H.p [] [ viewMarkdown product.summary ]
                    , H.div [ A.class "space" ] []
                    , List.map viewImage
                        (if List.isEmpty product.images then
                            [ logoURL ]

                         else
                            product.images
                        )
                        |> H.div [ A.class "row scroll" ]
                    ]
                , H.nav
                    [ A.class "padding" ]
                    [ case ( product.published, product.stock == 0 ) of
                        ( False, _ ) ->
                            H.text "Not published"

                        ( True, True ) ->
                            i18n lang "Out of stock"

                        ( _, _ ) ->
                            H.button
                                [ A.class "max"
                                , E.onClick <| ApiAddToCart product.id 1
                                ]
                                [ H.span []
                                    [ viewPrice lang product.price
                                    ]
                                , viewIconExtra "shopping_bag"
                                , H.span []
                                    [ i18n lang "Buy Now" ]
                                ]
                    ]
                ]


viewEmptyProduct : Lang -> H.Html Msg
viewEmptyProduct lang =
    H.div
        [ A.class mediumWrapper ]
        [ H.div [ A.class "center-align" ]
            [ viewIconExtra "category"
            , H.p
                []
                [ H.h5 [ A.class "center-align" ]
                    [ i18n lang "Promotional products are coming, please check on our site again at a later time" ]
                , H.div [ A.class "space" ] []
                ]
            ]
        ]


viewLatestCart : Lang -> Maybe Cart -> H.Html Msg
viewLatestCart lang cart =
    cart
        |> Maybe.map (viewCartSummary lang)
        |> Maybe.withDefault (viewEmptyCart lang)


viewEmptyCart : Lang -> H.Html Msg
viewEmptyCart lang =
    H.div
        [ A.class mediumWrapper ]
        [ H.div [ A.class "center-align" ]
            [ viewIconExtra "shopping_cart_off"
            , H.p
                []
                [ H.h5 [ A.class "center-align" ]
                    [ i18n lang "Your cart is empty" ]
                , H.div [ A.class "space" ] []
                , H.nav [ A.class "center-align" ]
                    [ H.a
                        [ A.class "button"
                        , A.href <|
                            Page.toString PageProducts
                        ]
                        [ i18n lang "Start Shopping" ]
                    ]
                ]
            ]
        ]


viewCartThumbnail : String -> H.Html Msg
viewCartThumbnail im =
    H.img
        [ A.class "circle large"
        , A.src <| thumbnailURL im
        ]
        []


viewCartItem : Lang -> Bool -> LineItem -> H.Html Msg
viewCartItem lang editable item =
    let
        thumb =
            item.product
                |> Maybe.map .images
                |> Maybe.andThen List.head
                |> Maybe.withDefault logoURL
                |> viewCartThumbnail

        brief =
            let
                qty =
                    String.fromInt item.quantity
            in
            item.product
                |> Maybe.map .title
                |> Maybe.withDefault "item(s)"
                |> (++) " x "
                |> (++) qty

        productPrice =
            item.product
                |> Maybe.map .price
                |> Maybe.map (viewPrice lang)
                |> Maybe.withDefault (i18n lang " - ")

        viewItemEdit =
            if editable then
                H.div []
                    [ H.button
                        [ A.class "button"
                        , E.onClick <| ApiAddToCart item.id 1
                        ]
                        [ viewIcon "add" ]
                    , H.button
                        [ A.class "button tertiary"
                        , E.onClick <| ApiAddToCart item.id -1
                        ]
                        [ viewIcon "remove" ]
                    ]

            else
                H.div [] []
    in
    H.article []
        [ H.div [ A.class "row" ]
            [ thumb
            , H.div [ A.class "max" ]
                [ H.b []
                    [ H.text brief
                    ]
                , H.p []
                    [ productPrice ]
                ]
            , viewItemEdit
            ]
        ]


viewCartAction : Lang -> Cart -> H.Html Msg
viewCartAction lang cart =
    case ( cart.checkout, cart.state ) of
        ( Nothing, _ ) ->
            H.nav []
                [ H.button
                    [ A.class "button max"
                    , E.onClick <| ApiCheckoutCart cart
                    ]
                    [ H.span [] [ viewPrice lang cart.total ]
                    , viewIcon "shopping_bag"
                    , H.span [] [ i18n lang "Confirm Order" ]
                    ]
                ]

        ( Just checkout, StateOngoing ) ->
            H.nav []
                [ H.a
                    [ A.class "button max"
                    , A.href <| Maybe.withDefault "" checkout.link
                    ]
                    [ H.span [] [ viewPrice lang cart.total ]
                    , viewIcon "shopping_bag"
                    , H.span [] [ i18n lang "Go to Payment Now" ]
                    ]
                ]

        ( Just checkout, StatePending ) ->
            H.nav []
                [ H.a
                    [ A.class "button max"
                    , A.href <| Maybe.withDefault "" checkout.link
                    ]
                    [ H.span [] [ viewPrice lang cart.total ]
                    , viewIcon "shopping_bag"
                    , H.span [] [ i18n lang "Go to Payment Now" ]
                    ]
                ]

        ( _, state ) ->
            let
                itemText =
                    String.join " " <|
                        [ stringFromState state
                        , String.fromInt (quantityItems cart.items)
                        , "items"
                        ]

                remarksText =
                    cart.checkout
                        |> Maybe.andThen .remarks
                        |> Maybe.map (\s -> " (" ++ s ++ ")")
                        |> Maybe.withDefault ""
            in
            H.nav []
                [ H.div [ A.class "tertiary responsive padding" ]
                    [ H.b [] [ H.text <| formatDateTime cart.metadata.updated ]
                    , H.hr [] []
                    , H.br [] []
                    , H.p []
                        [ H.text <| itemText ++ remarksText ]
                    , H.p []
                        [ viewPrice lang cart.total ]
                    , H.div [ A.class "space" ] []
                    ]
                ]


cartIsEditable : Cart -> Bool
cartIsEditable cart =
    case cart.state of
        StateOngoing ->
            True

        StatePending ->
            True

        _ ->
            False


viewCartSummary : Lang -> Cart -> H.Html Msg
viewCartSummary lang cart =
    H.div []
        [ H.h6 []
            [ H.text <|
                cartStateTitle cart
            ]
        , H.div [] <|
            List.map (viewCartItem lang <| cartIsEditable cart) cart.items
        , viewCartAction lang cart
        ]


viewOrderSummary : Lang -> WebData Cart -> H.Html Msg
viewOrderSummary lang result =
    let
        viewWrapper viewTitle =
            H.div
                [ A.class largeWrapper ]
                [ H.div [ A.class "center-align" ]
                    [ H.h5 [] [ viewTitle ] ]
                ]
    in
    case result of
        RemoteData.NotAsked ->
            viewWrapper <|
                i18n lang "Please sign in to continue or refresh the page"

        RemoteData.Failure err ->
            viewWrapper <|
                H.text <|
                    httpError lang err

        RemoteData.Loading ->
            viewWrapper viewLoading

        RemoteData.Success cart ->
            H.div [ A.class "padding" ]
                [ H.h6 []
                    [ H.text <|
                        cartStateTitle cart
                    ]
                , H.div [] <|
                    List.map (viewCartItem lang <| cartIsEditable cart) cart.items
                , viewCartAction lang cart
                ]


viewAdminOrder : Lang -> Cart -> H.Html Msg
viewAdminOrder lang order =
    let
        remarksText =
            order.checkout
                |> Maybe.andThen .remarks
                |> Maybe.map (\s -> " (" ++ s ++ ")")
                |> Maybe.withDefault ""
    in
    H.article [ A.id order.id, A.class "border round" ]
        [ H.details []
            [ H.summary []
                [ H.label []
                    [ H.text <| formatDateTime order.metadata.updated
                    , H.span [ A.class "padding" ] []
                    ]
                , H.b []
                    [ order.user
                        |> Maybe.map .name
                        |> Maybe.withDefault "Name: N/A"
                        |> H.text
                    , H.span [ A.class "padding" ] []
                    ]
                , H.b []
                    [ order.user
                        |> Maybe.map .email
                        |> Maybe.withDefault "Email: N/A"
                        |> H.text
                    , H.span [ A.class "padding" ] []
                    ]
                , H.b []
                    [ order.user
                        |> Maybe.map .phone
                        |> Maybe.withDefault "Phone: N/A"
                        |> H.text
                    , H.span [ A.class "padding" ] []
                    ]
                , H.div [ A.class "divider" ] []
                , viewPrice lang order.total
                , H.span [ A.class "padding" ] []
                , H.label []
                    [ [ "This"
                      , stringFromState order.state
                      , "order contains"
                      , String.fromInt (quantityItems order.items)
                      , "items"
                      ]
                        |> String.join " "
                        |> H.text
                    , H.b [] [ H.text remarksText ]
                    ]
                ]
            , H.p [ A.class "padding" ]
                [ H.b []
                    [ order.user
                        |> Maybe.map .address
                        |> Maybe.map formatAddress
                        |> Maybe.withDefault "Address: N/A"
                        |> H.text
                    ]
                , H.div [ A.class "divider" ] []
                , H.span [ A.class "padding" ] []
                , H.div [] <|
                    List.map (viewCartItem lang False) order.items
                ]
            , H.button
                [ A.class <|
                    "small"
                        ++ (if order.state == StateShipped then
                                " border"

                            else
                                ""
                           )
                , E.onClick <|
                    if order.state == StateShipped then
                        NoOp

                    else
                        ApiShipOrder order.id
                ]
                [ viewIcon "flight", H.span [] [ i18n lang "Ship" ] ]
            , H.button
                [ A.class <|
                    "small"
                        ++ (if order.state == StateDelivered then
                                " border"

                            else
                                ""
                           )
                , E.onClick <|
                    if order.state == StateDelivered then
                        NoOp

                    else
                        ApiDeliverOrder order.id
                ]
                [ viewIcon "playlist_add_check", H.span [] [ i18n lang "Deliver" ] ]
            , H.button
                [ A.class <|
                    "small tertiary"
                        ++ (if order.state == StateOrdered then
                                " border"

                            else
                                ""
                           )
                , E.onClick <|
                    if order.state == StateOrdered then
                        NoOp

                    else
                        ApiRevertOrder order.id
                ]
                [ viewIcon "undo", H.span [] [ i18n lang "Revert to Ordered" ] ]
            , H.button
                [ A.class <|
                    "small tertiary"
                        ++ (if order.state == StateCancelled then
                                " border"

                            else
                                ""
                           )
                , E.onClick <|
                    if order.state == StateCancelled then
                        NoOp

                    else
                        ApiCancelOrder order.id
                ]
                [ viewIcon "delete_forever", H.span [] [ i18n lang "Cancel" ] ]
            , H.button
                [ A.class <|
                    "small tertiary"
                        ++ (if order.state == StateRefunded then
                                " border"

                            else
                                ""
                           )
                , E.onClick <|
                    if order.state == StateRefunded then
                        NoOp

                    else
                        ApiRefundOrder order.id
                ]
                [ viewIcon "money_off", H.span [] [ i18n lang "Refund" ] ]
            ]
        ]


viewAdminOrders : Model -> H.Html Msg
viewAdminOrders model =
    let
        viewWrapper viewTitle =
            H.div
                [ A.class largeWrapper ]
                [ H.div [ A.class "center-align" ]
                    [ H.h5 [] [ viewTitle ] ]
                ]
    in
    case model.orders of
        RemoteData.NotAsked ->
            viewWrapper <|
                i18n model.lang "Please sign in to continue or refresh the page"

        RemoteData.Failure err ->
            viewWrapper <|
                H.text <|
                    httpError model.lang err

        RemoteData.Loading ->
            viewWrapper viewLoading

        RemoteData.Success orders ->
            sortOrders model.search model.sorting orders
                |> List.map (viewAdminOrder model.lang)
                |> H.div [ A.class "container padding" ]


formatAddress : Maybe Address -> String
formatAddress address =
    case address of
        Nothing ->
            "N/A"

        Just a ->
            [ a.country
            , a.city
            , a.line1
            , Maybe.withDefault "" a.line2
            , Maybe.withDefault "" a.postcode
            ]
                |> List.filter (\f -> not (String.isEmpty f))
                |> String.join ", "


viewTextInput : String -> String -> Field -> H.Html Msg
viewTextInput subject txt field =
    H.div [ A.class "field border round fill small max" ]
        [ H.input
            [ A.attribute "type" "text"
            , A.value txt
            , E.onInput <| EditField field
            ]
            []
        , H.span [ A.class "helper" ] [ H.text subject ]
        ]


viewTextAreaInput : String -> String -> Field -> H.Html Msg
viewTextAreaInput _ txt field =
    H.div [ A.class "grid" ]
        [ H.div [ A.class "s12 m6 max" ]
            [ H.textarea
                [ A.style "width" "100%"
                , A.style "height" "400px"
                , A.style "background-color" "rgb(255,255,255)"
                , A.style "padding" "5px"
                , A.style "overflow" "scroll"
                , A.value txt
                , E.onInput <| EditField field
                , A.rows 10
                ]
                []
            ]
        , H.div
            [ A.class "s12 m6 left-align"
            , A.style "padding" "5px"
            , A.style "overflow" "scroll"
            , A.style "background-color" "rgb(255,255,255)"
            ]
            [ viewMarkdown txt ]
        ]


viewMarkdown : String -> H.Html Msg
viewMarkdown txt =
    H.div
        [ A.class "max left-align padding"
        , A.style "background-color" "white"
        , A.style "list-style-type" "none"
        ]
        [ Markdown.Render.toHtml Extended txt
            |> H.map MarkdownMsg
        ]


viewNumberInput : String -> Int -> Field -> H.Html Msg
viewNumberInput subject num field =
    H.div [ A.class "field border round fill small max" ]
        [ H.input
            [ A.attribute "type" "number"
            , A.value <| String.fromInt num
            , E.onInput <|
                EditField field
            ]
            []
        , H.span [ A.class "helper" ] [ H.text subject ]
        ]


viewTextSelect : String -> List String -> String -> Field -> H.Html Msg
viewTextSelect subject opts selected field =
    let
        viewOption opt =
            H.option
                [ A.value opt
                , A.selected <| opt == selected
                ]
                [ H.text opt ]
    in
    H.div [ A.class "field label suffix border small round" ]
        [ H.select [ E.onInput <| EditField field ] <| List.map viewOption opts
        , H.label [] [ H.text subject ]
        , viewIcon "arrow_drop_down"
        ]


viewCategorySelect : Category -> Bool -> Field -> H.Html Msg
viewCategorySelect cat bool field =
    H.div []
        [ H.input
            [ A.attribute "type" "checkbox"
            , A.checked bool
            , E.onClick <| EditField field (stringFromCategory cat)
            ]
            []
        , H.span [ A.class "helper padding" ] [ H.text <| stringFromCategory cat ]
        ]


viewToggleInput : String -> Bool -> Field -> H.Html Msg
viewToggleInput subject bool field =
    H.div []
        [ H.label [ A.class "switch" ]
            [ H.input
                [ A.attribute "type" "checkbox"
                , A.checked bool
                , E.onClick <| EditField field ""
                ]
                []
            , H.span [ A.class "helper padding" ] [ H.text subject ]
            ]
        ]


viewImageSelect : String -> Field -> H.Html Msg
viewImageSelect im field =
    H.div []
        [ viewCartThumbnail im
        , H.label [ E.onClick <| EditField field im ]
            [ H.span
                []
                [ viewIcon "delete" ]
            ]
        , H.div [ A.class "divider" ] []
        ]


viewImageUpload : H.Html Msg
viewImageUpload =
    H.button [ A.class "circle" ]
        [ viewIcon "attach_file"
        , H.input
            [ A.attribute "type" "file"
            , A.multiple True
            , E.on "change"
                (Json.Decode.map ApiUploadProductImage uploadDecoder)
            ]
            []
        ]


uploadDecoder : Json.Decode.Decoder (List File.File)
uploadDecoder =
    Json.Decode.at [ "target", "files" ] (Json.Decode.list File.decoder)


viewAdminProduct : Lang -> WebData Product -> H.Html Msg
viewAdminProduct lang result =
    let
        viewWrapper viewTitle =
            H.div
                [ A.class largeWrapper ]
                [ H.div [ A.class "center-align" ]
                    [ H.h5 [] [ viewTitle ] ]
                ]
    in
    case result of
        RemoteData.NotAsked ->
            viewWrapper <|
                i18n lang "Please refresh the page"

        RemoteData.Failure err ->
            viewWrapper <|
                H.text <|
                    httpError lang err

        RemoteData.Loading ->
            viewWrapper viewLoading

        RemoteData.Success product ->
            let
                currencies =
                    List.map stringFromCurrency currencyVariants
            in
            H.div
                [ A.class "fill center-align padding" ]
                [ H.div
                    [ A.class "center-align" ]
                    [ H.h5
                        [ A.class "center-align" ]
                        [ H.text <|
                            case product.id of
                                "new" ->
                                    "New Product"

                                _ ->
                                    "Edit Product"
                        ]
                    , H.p [] [ viewTextInput "Title" product.title ProductTitle ]
                    , H.p [] [ viewTextAreaInput "Summary" product.summary ProductSummary ]
                    , H.p [ A.class "left-align border round padding" ]
                        [ H.b [] [ i18n lang "Pick Categories" ]
                        , H.div [] <|
                            List.map
                                (\cat ->
                                    viewCategorySelect cat (List.member cat product.categories) (ProductCategories cat)
                                )
                                categoryVariants
                        ]
                    , H.br [] []
                    , H.p []
                        [ viewTextSelect "Currency" currencies (stringFromCurrency product.price.currency) ProductCurrency
                        , viewNumberInput ("Price: " ++ formatPrice product.price) product.price.amount ProductPrice
                        , viewNumberInput "Discount: " product.price.discount ProductDiscountPrice
                        ]
                    , H.p []
                        [ viewNumberInput ("Stock: " ++ String.fromInt product.stock) product.stock ProductStock
                        ]
                    , H.hr [] []
                    , case product.id of
                        "new" ->
                            H.b [] [ i18n lang "Save your new product before uploading images" ]

                        _ ->
                            H.div [ A.class "row scroll" ] <|
                                viewImageUpload
                                    :: List.map (\im -> viewImageSelect im ProductImages) product.images
                    , H.div [ A.class "space" ] []
                    , H.p [ A.class "row" ]
                        [ viewToggleInput "Published?" product.published ProductPublished ]
                    ]
                , H.nav
                    [ A.class "padding" ]
                    [ H.button
                        [ A.class "max"
                        , E.onClick <| ApiPutProduct
                        ]
                        [ H.span []
                            [ viewIcon "save"
                            , H.span []
                                [ case product.id of
                                    "new" ->
                                        i18n lang "Create"

                                    _ ->
                                        i18n lang "Update"
                                ]
                            ]
                        ]
                    ]
                ]
